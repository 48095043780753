import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));

export const displayVendorRequests = createAsyncThunk('displayVendorRequests',async()=>{
    try{
        const response = await axios.get(`${host}/vendorRequest_update`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})

export const approveRequest = createAsyncThunk('approveRequest',async(id)=>{

    const formData = new FormData();
    formData.append('_method','PUT'); 
    formData.append('id', id);

    try{
        const response = await axios.post(`${host}/vendorRequest_update/`+id, formData,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const denyRequest = createAsyncThunk('denyRequest',async(id)=>{
    console.log("Deny Request : ",id);
    return axios.delete(`${host}/vendorRequest_update/${id}`,{
        headers: {
            "Authorization": `Bearer ${sessionUser.token}`
        }
    })
        .then((res) => res.data)
})

export const showPrice = createAsyncThunk('showPrice',async(id)=>{

    console.log("Show Price Id: ", id)

    try{
        const response = await axios.get(`${host}/showPrice/${id}`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error',error);
    }
})


const initialState ={
    VendorRequests:[],
    showPrices:[],
}

const VendorRequests=createSlice({
    name:'VendorRequests',
    initialState,
    reducers:{

    },
    extraReducers: (builder)=>{
           
        builder.addCase(displayVendorRequests.fulfilled,(state,action)=>{
            state.VendorRequests=action.payload;
        })

        builder.addCase(approveRequest.fulfilled,(state,action)=>{
           window.location.href = '/VendorRequests';
        })

        builder.addCase(denyRequest.fulfilled,(state,action)=>{
           window.location.href = '/VendorRequests';
        })

        builder.addCase(showPrice.fulfilled,(state,action)=>{
            state.showPrices=action.payload;
        })

    }
})

// export const {}=VendorRequests.actions;
export default VendorRequests.reducer;