import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import "../App.css";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import MenuIcon from '@mui/icons-material/Menu';
import { logout } from "../Slice/LoginSlice";
import { useDispatch } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const open = false;

  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));
  const dispatch = useDispatch();

  const handleLocal = () => {
    const data = {
      token: localUser.token,
    };
    dispatch(logout(data));
    localStorage.clear();
  };

  const handleSession = () => {
    const data = {
      token: sessionUser.token,
    };
    dispatch(logout(data));
    sessionStorage.clear();
  };

  const icons = [
    <MenuIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
    <Groups2OutlinedIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
    <AdsClickOutlinedIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
    <CurrencyRupeeOutlinedIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
    <LibraryBooksOutlinedIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
    <Groups2OutlinedIcon sx={{ marginRight: 1, color: "#E74C3C" }} />,
  ];

  const routes = ["/Dashboard","/Vendors", "/Subscription", "/Payment", "/Booking","/VendorRequests"];

  const texts = ["Dashboard","Vendors", "Subscription", "Payments", "Bookings","New Request"];

  return (
    <>
      <AppBar open={open} position="fixed">
        <div className="container-fluid">
          <div className="row">
            <div className="col-1 me-auto bg-white">
              <img
                src="../images/logo.png"
                className="mr-2"
                style={{ width: "100%" }}
                alt="logo"
              />
            </div>

            <div className="col-11">
              <Toolbar
                style={{ backgroundColor: "#E74C3C" }}
                className="main-toolbar"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="h6"
                    noWrap
                    component="div"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <img
                      src="../images/small-logo.png"
                      className="mr-2 miniLogo"
                      style={{ width: "18%" }}
                      alt="logo"
                    />
                    <h2
                      className="pageTitle"
                      style={{
                        margin: "0",
                        marginRight: "30px",
                        fontFamily: "Brush Script MT, cursive ",
                        fontWeight: "bold",
                      }}
                    >
                      Homey Bites Tiffins - Admin Panel
                    </h2>
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      marginLeft: "auto",
                    }}
                  >
                    <h6
                      style={{ margin: "0", marginRight: "30px" }}
                      className="profileName"
                    >
                      Hi, {sessionUser.name}
                    </h6>
                    <h6 style={{ margin: "0", marginRight: "20px" }}>
                      <PersonIcon sx={{ marginRight: "1", color: "white" }} />
                    </h6>

                    <h6 style={{ margin: "0", marginRight: "20px" }}>
                      {localUser && localUser.name ? (
                        <Link
                          to="/"
                          onClick={handleLocal}
                          className="dropdown-item"
                        >
                          <LogoutIcon
                            sx={{ marginRight: "1", color: "white" }}
                          />
                        </Link>
                      ) : (
                        <Link
                          to="/"
                          onClick={handleSession}
                          className="dropdown-item"
                        >
                          <LogoutIcon
                            sx={{ marginRight: "1", color: "white" }}
                          />
                        </Link>
                      )}
                    </h6>
                  </div>
                </div>
              </Toolbar>

              <Toolbar sx={{ background: "white" }} className="second-toolbar">
                {texts.map((text, index) => (
                  <Typography
                    key={text}
                    variant="h7 outlined"
                    noWrap
                    component={Link}
                    to={routes[index]}
                    sx={{
                      color: "black",
                      textDecoration: "none",
                      paddingLeft: "40px",
                      display: "flex",
                      alignItems: "center",
                      lineHeight: "10px",
                    }}
                  >
                    {icons[index]} {text}
                  </Typography>
                ))}
              </Toolbar>
            </div>
          </div>
        </div>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{ minHeight: "128px"}}
          className="top-toolbar"
        ></DrawerHeader>
        <Divider />

        <List>
          {texts.map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 69,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
                component={Link}
                to={routes[index]}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    color: "#E74C3C",
                  }}
                >
                  {icons[index]}
                  <Typography
                    variant="caption"
                    sx={{
                      marginTop: 1,
                      fontSize: "0.6rem",
                      fontWeight: "bold",
                    }}
                  >
                    {text}
                  </Typography>
                </ListItemIcon>
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Divider />
      </Drawer>
    </>
  );
}
