import * as React from "react";
import { useEffect } from "react";
import "../App.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import {
  displayVendorRequests,
  approveRequest,
  denyRequest,
  showPrice
} from "../Slice/VendorRequests";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const columns = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mess_name", label: "Mess name" },
  { id: "mobile", label: "Mobile" },
  { id: "address", label: "Address" },
  { id: "pincode", label: "Pincode" },
  { id: "image", label: "Logo" },
  { id: "mess_category", label: "Mess Category" },
  { id: "pricing", label: "Pricing" },
  { id: "status", label: "Status" },
  { id: "Operations", label: "Operations" },
];

function createData(
  name,
  email,
  mess_name,
  mobile,
  address,
  pincode,
  image,
  mess_category,
  pricing,
  status,
  Operations
) {
  return {
    name,
    email,
    mess_name,
    mobile,
    address,
    pincode,
    image,
    mess_category,
    pricing,
    status,
    Operations,
  };
}

export default function MiniDrawer() {
  const { VendorRequests , showPrices } = useSelector((state) => state.VendorRequests);
  const dispatch = useDispatch();
  const file = process.env.REACT_APP_IMAGE;

  const pricerows = showPrices
  ? showPrices.flatMap((vendor) =>
      vendor.mess_category.split(",").map((category, index) => {
        const tiffinPrices = vendor.tiffin_price.split(",");
        const monthlyPrices = vendor.monthly_price.split(",");
        const tiffinPrice = tiffinPrices[index]
          ? tiffinPrices[index].trim()
          : "";
        const monthlyPrice = monthlyPrices[index]
          ? monthlyPrices[index].trim()
          : "";
        return {
          vendorId: vendor.id,
          category: category.trim(),
          tiffin_price: tiffinPrice,
          monthly_price: monthlyPrice,
          mess_category: vendor.mess_category.trim(),
        };
      })
    )
  : [];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    dispatch(displayVendorRequests());
  }, [dispatch]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selectedStatus, setSelectedStatus] = React.useState("Pending"); 

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredVendorRequests = VendorRequests
    ? VendorRequests.filter((vendor) => {
        if (selectedStatus === 'Approved') {
          return vendor.status === 'Approved';
        } else if (selectedStatus === 'Rejected') {
          return vendor.status === 'Rejected';
        } else 
        {
          return vendor.status === 'Pending';
        }
      })
    : [];

    const rows = filteredVendorRequests.map((vendor) => {
      const actions =
        vendor.status === "Pending" ? (
          <div className="d-flex gap-2">
            <button
              type="submit"
              className="btn btn-outline-success btn-sm"
              onClick={() => dispatch(approveRequest(vendor.id))}
            >
              Approve
            </button>
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={() => dispatch(denyRequest(vendor.id))}
            >
              Reject
            </button>
          </div>
        ) : null;
    
      return createData(
        vendor.name,
        vendor.email,
        vendor.mess_name,
        vendor.mobile,
        vendor.address,
        vendor.pincode,
        <img src={`${file}${vendor.image}`} width="60px" alt="img" />,
        vendor.mess_category,
        <button
          type="button"
          className="btn btn-outline-warning btn-sm"
          onClick={() => {
            handleOpen();
            dispatch(showPrice(vendor.id));
          }}
        >
          Show
        </button>,
        vendor.status,
        actions
      );
    });    


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Header />
      <div className="TableBox">
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader
            style={{ minHeight: "128px" }}
            className="top-toolbar"
          />

          {/* Add New Subscription Modal Starts*/}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
            <div style={{ flex: "1", textAlign: "center" }}>
              <h2 style={{ color: "#E74C3C", margin: 0, fontWeight: "bold" }} className="mt-1 mb-4">
                Vendor Requests
              </h2>
            </div>

            <Box sx={{ minWidth: 110 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" color="success" htmlFor="status-select">
                  Status
                </InputLabel>
                <NativeSelect
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  inputProps={{ name: 'status', id: 'status-select'}}>
                  <option value={"Approved"} style={{ textAlign: "center" }}>Approved</option>
                  <option value={"Rejected"} style={{ textAlign: "center" }}>Rejected</option>
                  <option value={"Pending"} style={{ textAlign: "center" }}>Pending</option>
                </NativeSelect>
              </FormControl>
            </Box>  

          </div>

          <div>
            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description">
              <Box>
                <Typography>
                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-6 m-auto grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div style={{ flex: "1" }}>
                                  <h3
                                    style={{ color: "#E74C3C", margin: 0 }}
                                    className="mt-1 mb-4"
                                  >
                                    Pricing
                                  </h3>
                                </div>
                                <div>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#E74C3C",
                                    }}
                                    onClick={handleClose}
                                  >
                                    X
                                  </Button>
                                </div>
                              </div>
                              <TableContainer
                                component={Paper}
                                style={{ marginTop: "20px" }}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "#E74C3C",
                                      color: "white",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Category
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Tiffin Price
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Monthly Price
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {pricerows.map((row) => (
                                      <TableRow
                                        key={row.category}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.category}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.tiffin_price}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.monthly_price}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>



          
          {/* Add New Subscription Modal Ends*/}

          {/* Table Starts*/}
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              margin: "auto",
              backgroundColor: "transparent",
            }}
            className="paperVendorRequests"
          >
            <TableContainer
              sx={{
                maxHeight: 375,
                overflowX: "auto",
              }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          color: "White",
                          fontWeight: "bold",
                          backgroundColor: "#E74C3C",
                          textAlign: "center",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ textAlign: "center" }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {/* Table Ends*/}
        </Box>
      </div>
    </Box>
  );
}
