
import { createAsyncThunk,createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));

export const loginValue =createAsyncThunk('loginValue',async(data,{rejectWithValue})=>{
    console.log("Login Data : ",data)
    const formData=new FormData();
    formData.append('email',data.email);
    formData.append('password',data.password);
    try{
        const response = await axios.post(`${host}/login`,formData);
        return response.data;
    }
    catch (error){
        return rejectWithValue(error.response.data);
    }
})


export const logout = createAsyncThunk('logout', async (data ,{rejectWithValue})=>{
    console.log("Logout Data : ",data)
    try
    {
        const formData = new FormData();
        formData.append("login", data.token);
        const response = await axios.post(`${host}/logout`, formData, {
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data;
    }
    catch(error)
    {   
        return rejectWithValue(error.response.data);
    }
});

const initialState={
    email : '',
    password:'',
    logindata:[],  
    token:'', 
    checked:'',
    errors:[],
    loading:false,
}

const LoginSlice=createSlice({
    name:'LoginSlice',
    initialState,
    reducers:{
        setEmail:(state,payload)=>{
            state.email=payload.payload;
        },
        setPassword:(state,payload)=>{
            state.password=payload.payload;
        }
    },
    extraReducers:(builder)=>{

        builder.addCase(loginValue.pending,(state,action)=>{
            state.loading = true;
        })

        builder.addCase(loginValue.fulfilled,(state,action)=>{
            state.loading = false;
            var result = action.payload;
            console.log("Login User Data : ",result)

            const user = {name:result.name, token:result.token};

            if(state.checked)
            {
                localStorage.setItem('user',JSON.stringify(user));
                console.log('logged using local storage')
                window.location.href="/Dashboard";
            }
            else
            {
                sessionStorage.setItem('user',JSON.stringify(user));
                console.log('logged using session storage')
                window.location.href="/Dashboard";
            }
        })

        builder.addCase(loginValue.rejected ,(state,action) =>{
            console.log(action.payload)
            state.loading = false;
            state.errors = action.payload;
        })     

        builder.addCase(logout.fulfilled ,(state,action) =>{
            window.location.href="/";
            console.log(action.payload)
        })  
        builder.addCase(logout.rejected ,(state,action) =>{
            console.log(action.payload)
        })

    }
})
export const {setEmail,setPassword}=LoginSlice.actions;
export default LoginSlice.reducer;