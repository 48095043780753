import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { setEmail, setPassword, loginValue } from "../Slice/LoginSlice";
import { RotatingLines } from 'react-loader-spinner';
import "../App.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Login = () => {
  const { email, password, errors, loading } = useSelector((state) => state.LoginSlice);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    const data = { email, password };
    dispatch(loginValue(data));
  };

  return (
<div className="TableBox">
  <Box component="main">
    <DrawerHeader className="top-toolbar" />
    <div>
      <Card className="form-card custom-card" style={{ borderRadius: '30px' }}>
        <CardContent style={{ textAlign: "center" }}>
          <img src="../images/logo.png" style={{ width: "150px" }} alt="logo" />
          <Typography color="text.secondary" gutterBottom style={{ fontSize: "25px", color: 'black' }}>
            Admin Login
          </Typography>
          <form onSubmit={handleLogin}>
            <TextField
              label="Email"
              value={email}
              style={{ marginTop: "20px", width: "100%", borderRadius: "10px" }}
              onChange={(e) => dispatch(setEmail(e.target.value))}
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              style={{ marginTop: "15px", width: "100%", borderRadius: "10px" }}
              onChange={(e) => dispatch(setPassword(e.target.value))}
            />

            <Button
              variant="contained"
              size="large"
              style={{ backgroundColor: "red", color: "white", borderRadius: "10px", marginTop: "10px" }}
              disabled={loading}
              type="submit"
            >
              {loading ? (
                <RotatingLines
                  visible={true}
                  height={24}
                  width={24}
                  color="white"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Login"
              )}
            </Button>
          </form>
          {(errors && errors.success === false && errors.data.error !== "Unauthorized Access") && (
            <div style={{ marginTop: "10px" }}>
              <div style={{ backgroundColor: "#ffe2e2", padding: "10px", borderRadius: "5px", border: "1px solid #c62828" }}>
                <p style={{ color: "#c62828", margin: "0" }}>{errors.data.error}</p>
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  </Box>
</div>


  );
};

export default Login;
