import * as React from "react";
import { useEffect } from "react";
import "../App.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";

import {displayBookingDetails} from "../Slice/BookingSlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const columns = [
  { id: "bid", label: "Payment Id" },
  { id: "uid", label: "User ID" },
  { id: "vid", label: "Mess Name" },
  { id: "s_date", label: "Start Date" },
  { id: "e_date", label: "End Date" },
  { id: "sid", label: "Subscription ID" },
  { id: "type", label: "Subscription Type" },
  { id: "status", label: "Status" },
];

function createData(bid, uid,vid,s_date,e_date,sid, type, status) {
  return { bid, uid,vid,s_date,e_date,sid, type, status };
}

export default function MiniDrawer() {

  const { bookingDetails } = useSelector((state) => state.BookingSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(displayBookingDetails());
  }, [dispatch]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = bookingDetails
    ? bookingDetails.map((subscription) => {
        return createData(
          subscription.bid,
          subscription.uname,
          subscription.messname,
          subscription.s_date,
          subscription.e_date,
          subscription.sid,
          subscription.type,
          subscription.status,
        );
      })
    : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  let siteToken = "";

  if (localUser && localUser.name !== "") {
    siteToken = localUser.token;
  } 
  else if (sessionUser && sessionUser.name !== "") {
    siteToken = sessionUser.token;
  }

  if (siteToken === "") {
    return <>{(window.location.href = "/")}</>;
  } 
  else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />

        <div className="TableBox">
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader
              style={{ minHeight: "128px" }}
              className="top-toolbar"
            />
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ flex: "1", textAlign: "center" }}>
                <h2
                  style={{ color: "#E74C3C", margin: 0, fontWeight: "bold" }}
                  className="mt-1 mb-4"
                >
                  Booking Details
                </h2>
              </div>
            </div>

            {/* Table Starts*/}
            <Paper
              sx={{
                width: "70%",
                overflow: "hidden",
                margin: "auto",
                backgroundColor: "transparent",
              }}
              className="paperSubscription"
            >
              <TableContainer
                sx={{
                  maxHeight: 375,
                  overflowX: "auto",
                }}
              >
            
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: "White",
                            fontWeight: "bold",
                            backgroundColor: "#E74C3C",
                            textAlign: "center",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ textAlign: "center" }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* Table Ends*/}
          </Box>
        </div>
      </Box>
    );
  }
}