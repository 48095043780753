import * as React from "react";
import { useEffect } from "react";
import "../App.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import {
  setName,
  setDesc,
  setPrice,
  setTiffin,
  displaySubscriptionData,
  EditSubscription,
  updateSubscription,
} from "../Slice/SubscriptionSlice";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const columns = [
  { id: "subid", label: "Subscription Id" },
  { id: "mess_id", label: "Mess Name" },
  { id: "plan_name", label: "Plan Name" },
  { id: "tiffin_price", label: "Tiffin Price" },
  { id: "monthly_price", label: "Monthly Price" },
  { id: "admin_tiffin_price", label: "admin Tiffin Price" },
  { id: "admin_monthly_price", label: "admin Monthly Price" },
  { id: "Update", label: "Update" },
];

function createData(subid,plan_name, mess_id, tiffin_price, monthly_price,admin_tiffin_price,admin_monthly_price,Update) {
  return { subid, plan_name, mess_id, tiffin_price, monthly_price,admin_tiffin_price,admin_monthly_price, Update};
}

export default function MiniDrawer() {

  const { name, desc, price, tiffin, subscription, id ,mess_id} = useSelector(
    (state) => state.SubscriptionSlice
  );

  const dispatch = useDispatch();

  const data1 = {
    id: id,
    name: name,
    desc: desc,
    price: price,
    tiffin: tiffin,
    mess_id:mess_id,
  };

  useEffect(() => {
    dispatch(displaySubscriptionData());
  }, [dispatch]);

  const [openUpdate, setOpenUpdate] = React.useState(false);

  const handleOpenUpdate = () => setOpenUpdate(true);
  const handleCloseUpdate = () => setOpenUpdate(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const rows = subscription
  ? subscription.map((subscription) => {
      const tf = subscription.tiffin_price;
      const mt = subscription.monthly_price;
      const datatiffin = tf.split(",");
      const datamonth = mt.split(",");

      return createData(
        subscription.subid,
        subscription.plan_name,
        subscription.mess_name,

        subscription.plan_name === "Veg" ? datatiffin[0] : datatiffin[1],
        subscription.plan_name === "Veg" ? datamonth[0] : datamonth[1],
      
        subscription.admin_tiffin_price,
        subscription.admin_monthly_price,

        <EditNoteOutlinedIcon
          onClick={() => {
            handleOpenUpdate();
            dispatch(EditSubscription(subscription.subid));
          }}
          sx={{ color: "orange" }}
        >
          Update
        </EditNoteOutlinedIcon>
      );
    })
  : [];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  let siteToken = "";

  if (localUser && localUser.name !== "") {
    siteToken = localUser.token;
  } else if (sessionUser && sessionUser.name !== "") {
    siteToken = sessionUser.token;
  }

  if (siteToken === "") {
    return <>{(window.location.href = "/")}</>;
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />

        <div className="TableBox">
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader
              style={{ minHeight: "128px" }}
              className="top-toolbar"
            />
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ flex: "1", textAlign: "center" }}>
                <h2
                  style={{ color: "#E74C3C", margin: 0, fontWeight: "bold" }}
                  className="mt-1 mb-4"
                >
                  Subscriptions
                </h2>
              </div>
            </div>

            {/* Update Subscription Modal Starts*/}
            <div>
              <Modal
                keepMounted
                open={openUpdate}
                onClose={handleCloseUpdate}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box>
                  <Typography>
                    <div className="main-panel">
                      <div className="content-wrapper">
                        <div className="row">
                          <div className="col-md-6 m-auto grid-margin stretch-card">
                            <div className="card">
                              <div className="card-body">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <div style={{ flex: "1" }}>
                                    <h3
                                      style={{ color: "#E74C3C", margin: 0 }}
                                      className="mt-1 mb-4"
                                    >
                                      Update Subscription
                                    </h3>
                                  </div>
                                  <div>
                                    <Button
                                      variant="contained"
                                      style={{
                                        color: "white",
                                        backgroundColor: "#E74C3C",
                                      }}
                                      onClick={handleCloseUpdate}
                                    >
                                      X
                                    </Button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 col-sm-12 mb-3">
                                    <TextField
                                      id="standard-search"
                                      label="Plan Name"
                                      type="input"
                                      variant="filled"
                                      fullWidth
                                      style={{ background: "transparent" }}
                                      onChange={(e) =>
                                        dispatch(setName(e.target.value))
                                      }
                                      value={name}
                                    />
                                  </div>
                                  <div className="col-md-6 col-sm-12 mb-3">
                                    <TextField
                                      id="standard-search"
                                      label="Mess Name"
                                      type="input"
                                      variant="filled"
                                      fullWidth
                                      style={{ background: "transparent" }}
                                      onChange={(e) =>
                                        dispatch(setDesc(e.target.value))
                                      }
                                      value={desc}
                                    />
                                  </div>

                                  {/* <div className="col-md-6 col-sm-12 mb-3">
                                    <TextField
                                      id="standard-search"
                                      label="Price"
                                      type="search"
                                      variant="filled"
                                      fullWidth
                                      style={{ background: "transparent" }}
                                      onChange={(e) =>
                                        dispatch(setPrice(e.target.value))
                                      }
                                      value={price}
                                    />
                                  </div> */}

<div className="col-md-6 col-sm-12 mb-3">
                                    <TextField
                                      id="standard-search"
                                      label="Tiffin Price"
                                      type="input"
                                      variant="filled"
                                      fullWidth
                                      style={{ background: "transparent" }}
                                      onChange={(e) =>
                                        dispatch(setTiffin(e.target.value))
                                      }
                                      value={tiffin}
                                    />
                                  </div>
                                  <div className="col-md-6 col-sm-12 mb-3">
                                    <TextField
                                      id="standard-search"
                                      label="Monthly Price"
                                      type="input"
                                      variant="filled"
                                      fullWidth
                                      style={{ background: "transparent" }}
                                      onChange={(e) =>
                                        dispatch(setPrice(e.target.value))
                                      }
                                      value={price}
                                    />
                                  </div>
                                </div>

                                <div className="row justify-content-center mt-3">
                                  <div className="col-md-6 col-sm-12 text-center">
                                    <Button
                                      variant="contained"
                                      color="warning"
                                      onClick={() =>
                                        dispatch(updateSubscription(data1))
                                      }
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Typography>
                </Box>
              </Modal>
            </div>
            {/* Update Subscription Modal Ends*/}

            {/* Table Starts*/}
            <Paper
              sx={{
                width: "70%",
                overflow: "hidden",
                margin: "auto",
                backgroundColor: "transparent",
              }}
              className="paperSubscription"
            >
              <TableContainer
                sx={{
                  maxHeight: 375,
                  overflowX: "auto",
                }}
              >
            
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: "White",
                            fontWeight: "bold",
                            backgroundColor: "#E74C3C",
                            textAlign: "center",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ textAlign: "center" }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                          
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* Table Ends*/}
          </Box>
        </div>
      </Box>
    );
  }
}
