import * as React from "react";
import { useEffect } from "react";
import "../App.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { displayData, blockVendor, findCustomers } from "../Slice/VendorSlice";
import { Button, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Modal from "@mui/material/Modal";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const columns = [
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "mess_name", label: "Messname" },
  { id: "mobile", label: "Mobile" },
  { id: "address", label: "Address" },
  { id: "pincode", label: "Pincode" },
  { id: "image", label: "image" },
  { id: "mess_category", label: "Mess Category" },
  { id: "status", label: "Status" },
  { id: "block", label: "Block" },
  { id: "customers", label: "View Customers" },
];

function createData(
  name,
  email,
  mess_name,
  mobile,
  address,
  pincode,
  image,
  mess_category,
  status,
  block,
  customers
) {
  return {
    name,
    email,
    mess_name,
    mobile,
    address,
    pincode,
    image,
    mess_category,
    status,
    block,
    customers
  };
}

export default function MiniDrawer() {
  const { vendor , customerList } = useSelector((state) => state.VendorSlice);
  const dispatch = useDispatch();

  const file = process.env.REACT_APP_IMAGE;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    dispatch(displayData());
  }, [dispatch]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [selectedStatus, setSelectedStatus] = React.useState("Active");
  const [selectedCategory, setSelectedCategory] = React.useState("Non-Veg");

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const customerDetails = customerList && customerList.map((customer) => ({
    name: customer.name,
    email: customer.email,
    mobile: customer.mobile,
    address: customer.working_address
}));


  const filteredVendorRequests = vendor
    ? vendor.filter((vendor) => {
        if (selectedStatus === "Active") {
          if (selectedCategory === "Non-Veg") {
            return (
              vendor.status === "Active" &&
              vendor.mess_category === "Veg,Non-Veg"
            );
          } else {
            return vendor.status === "Active" && vendor.mess_category === "Veg";
          }
        } else {
          return vendor.status === "Blocked";
        }
      })
    : [];

  const rows = filteredVendorRequests.map((vendor) => {
    return createData(
      vendor.name,
      vendor.email,
      vendor.mess_name,
      vendor.mobile,
      vendor.address,
      vendor.pincode,
      <img src={`${file}` + vendor.image} width="60px" alt="img" />,
      vendor.mess_category,
      vendor.status,
      <Button
        variant="outlined"
        style={{
          color: vendor.status === "Blocked" ? "green" : "red",
          borderColor: vendor.status === "Blocked" ? "green" : "red",
        }}
        onClick={() =>
          dispatch(
            vendor.status === "Blocked"
              ? blockVendor(vendor.id)
              : blockVendor(vendor.id)
          )
        }
      >
        {vendor.status === "Blocked" ? "Unblock" : "Block"}
      </Button>,

<button
type="button"
className="btn btn-outline-warning btn-sm"
onClick={() => {
  handleOpen();
  dispatch(findCustomers(vendor.id));
}}
>
Show
</button>,

    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  let siteToken = "";

  if (localUser && localUser.name !== "") {
    siteToken = localUser.token;
  } else if (sessionUser && sessionUser.name !== "") {
    siteToken = sessionUser.token;
  }

  if (siteToken === "") {
    return <>{(window.location.href = "/")}</>;
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />
        <div className="TableBox">
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader
              style={{ minHeight: "128px" }}
              className="top-toolbar"
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                color="textSecondary"
                gutterBottom
                style={{ color: "#E74C3C", margin: 0, fontWeight: "bold" }}
              >
                Vendor Details
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* Status Selector */}
                <FormControl sx={{ marginRight: "50px" }}>
                  <InputLabel variant="standard" htmlFor="status-select">
                    Status
                  </InputLabel>
                  <NativeSelect
                    value={selectedStatus}
                    onChange={handleStatusChange}
                    inputProps={{ name: "status", id: "status-select" }}
                  >
                    <option value={"Active"}>Active</option>
                    <option value={"Blocked"}>Blocked</option>
                  </NativeSelect>
                </FormControl>

                {/* Category Selector */}
                <FormControl>
                  <InputLabel variant="standard" htmlFor="category-select">
                    Mess Category
                  </InputLabel>
                  <NativeSelect
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    inputProps={{ name: "category", id: "category-select" }}
                  >
                    <option value={"Veg"}>Veg</option>
                    <option value={"Non-Veg"}>Veg & Non-Veg</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </Box>



            <div>
            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description">
              <Box>
                <Typography>
                  <div className="main-panel">
                    <div className="content-wrapper">
                      <div className="row">
                        <div className="col-md-6 m-auto grid-margin stretch-card">
                          <div className="card">
                            <div className="card-body">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <div style={{ flex: "1" }}>
                                  <h3
                                    style={{ color: "#E74C3C", margin: 0 }}
                                    className="mt-1 mb-4"
                                  >
                                    Customer List
                                  </h3>
                                </div>
                                <div>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "white",
                                      backgroundColor: "#E74C3C",
                                    }}
                                    onClick={handleClose}
                                  >
                                    X
                                  </Button>
                                </div>
                              </div>
                              <TableContainer
                                component={Paper}
                                style={{ marginTop: "20px" }}
                              >
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead
                                    style={{
                                      backgroundColor: "#E74C3C",
                                      color: "white",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Email
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Mobile No.
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          textAlign: "center",
                                          color: "white",
                                        }}
                                      >
                                        Address
                                      </TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                  {customerDetails.map((row, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.name}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.email}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.mobile}
                                        </TableCell>
                                        <TableCell
                                          style={{ textAlign: "center" }}
                                        >
                                          {row.address}
                                        </TableCell>
                                      </TableRow>
                                    ))}

                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Typography>
              </Box>
            </Modal>
          </div>




            {/* Table Starts*/}
            <Paper
              sx={{
                width: "100%",
                overflow: "hidden",
                margin: "auto",
                backgroundColor: "transparent",
              }}
              className="paperSubscription"
            >
              <TableContainer
                sx={{
                  maxHeight: 375,
                  overflowX: "auto",
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                            color: "White",
                            fontWeight: "bold",
                            backgroundColor: "#E74C3C",
                            textAlign: "center",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.code}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ textAlign: "center" }}
                                >
                                  {column.format && typeof value === "number"
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            {/* Table Ends*/}
          </Box>
        </div>
      </Box>
    );
  }
}
