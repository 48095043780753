import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));


export const displayPaymentDetails = createAsyncThunk('displayPaymentDetails',async()=>{
    try{
        const response = await axios.get(`${host}/payment`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})

const initialState ={
    paymentDetails:[],
}

const PaymentSlice=createSlice({
    name:'PaymentSlice',
    initialState,
    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(displayPaymentDetails.fulfilled,(state,action)=>{
            state.paymentDetails=action.payload;
        })
    }
})

// export const {}=PaymentSlice.actions;
export default PaymentSlice.reducer;