import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));

export const displayBookingDetails = createAsyncThunk('displayBookingDetails',async()=>{
    try{
        const response = await axios.get(`${host}/booking`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})

const initialState = {
    bookingDetails:[],
}

const BookingSlice=createSlice({
    name:'BookingSlice',
    initialState,
    reducers:{

    },
    extraReducers: (builder)=>{
        builder.addCase(displayBookingDetails.fulfilled,(state,action)=>{
            state.bookingDetails=action.payload;
        })
    }
})

// export const {}=BookingSlice.actions;
export default BookingSlice.reducer;