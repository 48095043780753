import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Header from "./Header";
import "../App.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import {
  displayData,
  customerCount,
  countCustomerVendors,
} from "../Slice/VendorSlice";
import { displaySubscriptionData } from "../Slice/SubscriptionSlice";
import { displayVendorRequests } from "../Slice/VendorRequests";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export default function MiniDrawer() {
  const { vendor, totalCustomerCount, customerVendorCount } = useSelector(
    (state) => state.VendorSlice
  );
  const { subscription } = useSelector((state) => state.SubscriptionSlice);
  const { VendorRequests } = useSelector((state) => state.VendorRequests);

  const dispatch = useDispatch();

  const vendorCount = vendor.length;
  const subscriptionCount = subscription.length;

  const palette = ["#E74C3C", "#E75C23"];

  const pendingVendorRequests = VendorRequests.filter(
    (request) => request.status === "Pending"
  );
  const vendorRequestCount = pendingVendorRequests.length;
  const customer = totalCustomerCount.length;

  const vegCount = vendor.filter(
    (request) => request.mess_category === "Veg"
  ).length;
  const comboCount = vendor.filter(
    (request) => request.mess_category === "Veg,Non-Veg"
  ).length;

  useEffect(() => {
    dispatch(displayData());
    dispatch(countCustomerVendors());
    dispatch(displaySubscriptionData());
    dispatch(displayVendorRequests());
    dispatch(customerCount());
  }, [dispatch]);


  const localUser = JSON.parse(localStorage.getItem("user"));
  const sessionUser = JSON.parse(sessionStorage.getItem("user"));

  let siteToken = "";

  if (localUser && localUser.name !== "") {
    siteToken = localUser.token;
  } else if (sessionUser && sessionUser.name !== "") {
    siteToken = sessionUser.token;
  }

  if (siteToken === "") {
    return <>{(window.location.href = "/")}</>;
  } else {
    return (
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Header />

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            display: "flex",
            gap: 5,
            flexWrap: "wrap",
            marginTop: "130px",
          }}
        >
          <DrawerHeader
            style={{ minHeight: "128px" }}
            className="top-toolbar"
          />

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
              >
                Vendors
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#E74C3C", marginTop: "10px" }}
              >
                {vendorCount}
              </Typography>
            </CardContent>
            <Box
              sx={{
                backgroundColor: "#E74C3C",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <Groups2OutlinedIcon
                sx={{ width: 50, height: 50, color: "#FFFFFF" }}
              />
            </Box>
          </Card>

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
              >
                Subscriptions
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#E74C3C", marginTop: "10px" }}
              >
                {subscriptionCount}
              </Typography>
            </CardContent>
            <Box
              sx={{
                backgroundColor: "#E74C3C",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <AdsClickOutlinedIcon
                sx={{ width: 50, height: 50, color: "#FFFFFF" }}
              />
            </Box>
          </Card>

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
              >
                Vendor Requests
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#E74C3C", marginTop: "10px" }}
              >
                {vendorRequestCount}
              </Typography>
            </CardContent>
            <Box
              sx={{
                backgroundColor: "#E74C3C",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <Groups2OutlinedIcon
                sx={{ width: 50, height: 50, color: "#FFFFFF" }}
              />
            </Box>
          </Card>

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
              >
                Total Customers
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", color: "#E74C3C", marginTop: "10px" }}
              >
                {customer}
              </Typography>
            </CardContent>
            <Box
              sx={{
                backgroundColor: "#E74C3C",
                borderRadius: "50%",
                padding: "10px",
              }}
            >
              <Groups2OutlinedIcon
                sx={{ width: 50, height: 50, color: "#FFFFFF" }}
              />
            </Box>
          </Card>

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
              marginLeft: "60px",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
                className="mb-5"
              >
                Mess Category
              </Typography>

              <PieChart
                colors={palette}
                series={[
                  {
                    data: [
                      { id: 0, value: vegCount, label: "Veg" },
                      { id: 1, value: comboCount, label: "Combo" },
                    ],
                  },
                ]}
                width={400}
                height={200}
              />
            </CardContent>
          </Card>

          <Card
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "10px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <CardContent style={{ textAlign: "center", flex: "1" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#E74C3C" }}
              >
                Vendors With Their <br />
                Customers
              </Typography>
              {customerVendorCount && customerVendorCount.length > 0 ? (
  <BarChart
    xAxis={[{ scaleType: 'band', data: customerVendorCount.map(v => v.mess_name) }]}
    series={[{ data: customerVendorCount.map(v => v.customer_count) }]}
    colors={palette}
    width={500}
    height={300}
  />
) : (
  <div>Loading</div>
)}


            </CardContent>
          </Card>
        </Box>
      </Box>
    );
  }
}
