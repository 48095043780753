import SubscriptionSlice from "../Slice/SubscriptionSlice";
import VendorSlice from "../Slice/VendorSlice";
import LoginSlice from "../Slice/LoginSlice";
import PaymentSlice from "../Slice/PaymentSlice"
import BookingSlice from "../Slice/BookingSlice"
import { configureStore } from "@reduxjs/toolkit";
import VendorRequests from "../Slice/VendorRequests";

export const store = configureStore({
  reducer: {
    SubscriptionSlice: SubscriptionSlice,
    VendorSlice: VendorSlice,
    LoginSlice: LoginSlice,
    VendorRequests: VendorRequests,
    PaymentSlice:PaymentSlice,
    BookingSlice:BookingSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});