import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './Components/Dashboard'
import Subscription from './Components/Subscription'
import Vendor from './Components/Vendor'
import Payment from './Components/Payment'
import Booking from './Components/Booking'
import Login from './Components/Login'
import VendorRequests from './Components/VendorRequests'

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login/>}/>
      <Route path="/Dashboard" element={<Dashboard/>}/>
      <Route path="/Subscription" element={<Subscription/>}/>
      <Route path="/Vendors" element={<Vendor/>}/>
      <Route path="/Payment" element={<Payment/>}/>
      <Route path="/Booking" element={<Booking/>}/>
      <Route path="/VendorRequests" element={<VendorRequests/>}/>
    </Routes>
  </BrowserRouter>
  );
}
export default App;
