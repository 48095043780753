import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));

export const inputValue = createAsyncThunk('inputValue', async (data, { rejectWithValue }) => {
    console.log(data);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('desc', data.desc);
    formData.append('price', data.price);
    formData.append('duration', data.duration);
    try {
        const response = await axios.post(`${host}/subscription`, formData, {
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});


export const displaySubscriptionData = createAsyncThunk('displaySubscriptionData',async()=>{
    try{
        const response = await axios.get(`${host}/subscription`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})

export const EditSubscription = createAsyncThunk('EditSubscription',async(id)=>{
    try{
        const response = await axios.get(`${host}/subscription/${id}`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const updateSubscription = createAsyncThunk('updateSubscription',async(data)=>{
    console.log("Data to update : ",data);
      const formData = new FormData();
      formData.append('_method','PUT'); 
      formData.append('name', data.name);
      formData.append('desc', data.desc);
      formData.append('price',data.price);
      formData.append('tiffin',data.tiffin);
      formData.append('mess_id',data.mess_id);
     // console.log(formData);
      try {
        const response = await axios.post(`${host}/subscription/`+data.id, formData,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data; 
      } catch (error) {
        return error.response.data; 
      }
})

export const deleteSubscription = createAsyncThunk('deleteSubscription', async (id) => {
    return axios.delete(`${host}/subscription/${id}`,{
        headers: {
            "Authorization": `Bearer ${sessionUser.token}`
        }
    })
        .then((res) => res.data)
})

const initialState ={
    name:'',
    desc:'',
    price:'',
    tiffin:'',
    subscription:[],
    id:'',
    errors:[],
    mess_id:'',
}

const SubscriptionSlice=createSlice({
    name:'SubscriptionSlice',
    initialState,
    reducers:{
  
        setName:(state,payload)=>{
            state.name=payload.payload
        },
        setDesc:(state,payload)=>{
            state.desc=payload.payload
        },
        setPrice:(state,payload)=>{
            state.price=payload.payload
        },
        setTiffin:(state,payload)=>{
            state.tiffin=payload.payload
        },
        

    },
    extraReducers: (builder)=>{

        builder.addCase(inputValue.fulfilled,(state,action)=>{
            window.location.href = '/Subscription';
        })

        builder.addCase(inputValue.rejected, (state, action) => {
            state.errors = action.payload.errors;
            console.log(state.errors)
        })
            

        builder.addCase(displaySubscriptionData.fulfilled,(state,action)=>{
            state.subscription=action.payload;
        })

        builder.addCase(EditSubscription.fulfilled,(state,action)=>{
            state.id = action.payload.sid;
            state.name= action.payload.plan_name;
            state.desc= action.payload.mess_name;
            state.price= action.payload.mprice;
            state.tiffin= action.payload.tprice;
            state.mess_id=action.payload.mess_id;
            console.log(action.payload)
        })

        builder.addCase(updateSubscription.fulfilled,(state,action)=>{
           window.location.href = '/Subscription';
        })

        builder.addCase(deleteSubscription.fulfilled,(state,action)=>{
            window.location.href = '/Subscription';
        })
    }
})

export const {setName,setDesc,setPrice,setTiffin,setDuration}=SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;