import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const host = process.env.REACT_APP_HOST_URL;

const sessionUser = JSON.parse(sessionStorage.getItem("user"));

export const vendorValue = createAsyncThunk('vendorValue',async(data,{ rejectWithValue })=>{
    console.log(data);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('messname',data.messname);
    formData.append('mobile',data.mobile);
    formData.append('address',data.address);
    formData.append('pincode',data.pincode);
    formData.append('image',data.image);
    formData.append('subscription_id',data.subscriptionPlan);
    try {
        const response = await axios.post(`${host}/update_vendor`, formData, {
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const displayData = createAsyncThunk('displayData',async()=>{
    try{
        const response = await axios.get(`${host}/update_vendor`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const findCustomers = createAsyncThunk('findCustomers',async(id)=>{
    console.log("Customer Details By Vendor Id : ",id);
    try{
        const response = await axios.get(`${host}/findCustomerDetails/${id}`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const countCustomerVendors = createAsyncThunk('countCustomerVendors',async()=>{
    try{
        const response = await axios.get(`${host}/countCustomerVendors`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const customerCount = createAsyncThunk('customerCount',async()=>{
    try{
        const response = await axios.get(`${host}/customerCount`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
            return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const EditVendor = createAsyncThunk('EditVendor',async(id)=>{
    console.log(id);
    try{
        const response = await axios.get(`${host}/update_vendor/${id}`,{
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data;
    }
    catch(error){
        console.log('error');
    }
})


export const updateVendor = createAsyncThunk('updateVendor',async(data ,{ rejectWithValue })=>{
    console.log("Data to update : ",data);
      const formData = new FormData();
      formData.append('_method','PUT'); 
      formData.append('name', data.name);
      formData.append('email', data.email);
      formData.append('messname',data.messname);
      formData.append('mobile',data.mobile);
      formData.append('address',data.address);
      formData.append('pincode',data.pincode);
      formData.append('image',data.image);
      try {
        const response = await axios.post(`${host}/update_vendor/`+data.id, formData, {
            headers: {
                "Authorization": `Bearer ${sessionUser.token}`
            }
        });
        return response.data; 
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
})


export const blockVendor = createAsyncThunk('blockVendor', async (id) => {
    console.log(id);
    return axios.delete(`${host}/update_vendor/${id}`,{
        headers: {
            "Authorization": `Bearer ${sessionUser.token}`
        }
    })
        .then((res) => res.data)
})

const initialState ={
    name:'',
    email:'',
    messname:'',
    mobile:'',
    vendor:[],
    id:'',
    errors:[],
    address:'',
    pincode:'',
    image:'',
    subscriptionPlan:[],
    customerList:[],
    totalCustomerCount:[],
    customerVendorCount:[],
}

const VendorSlice=createSlice({
    name:'VendorSlice',
    initialState,
    reducers:{
  
        setName:(state,payload)=>{
            state.name=payload.payload
        },
        setEmail:(state,payload)=>{
            state.email=payload.payload
        },
        setMessname:(state,payload)=>{
            state.messname=payload.payload
        },
        setMobile:(state,payload)=>{
            state.mobile=payload.payload
        },
        setAddress:(state,payload)=>{
            state.address=payload.payload
        },
        setPincode:(state,payload)=>{
            state.pincode=payload.payload
        },
        setImage:(state,payload)=>{
            state.image=payload.payload
        },
        setSubscriptionPlan:(state,payload)=>{
            state.subscriptionPlan=payload.payload
        } 
    },
    extraReducers: (builder)=>{

        builder.addCase(vendorValue.fulfilled,(state,action)=>{
            console.log(action.payload);
            window.location.href = '/Vendors';
        })

        builder.addCase(vendorValue.rejected, (state, action) => {
            state.errors = action.payload.errors;
            console.log(state.errors)
        })

        builder.addCase(displayData.fulfilled,(state,action)=>{
            state.vendor=action.payload;
        })

        builder.addCase(findCustomers.fulfilled,(state,action)=>{
            state.customerList=action.payload;
        })

        builder.addCase(countCustomerVendors.fulfilled,(state,action)=>{
            state.customerVendorCount=action.payload;
            console.log("Vendor Customer : ", state.customerVendorCount);
        })

        builder.addCase(customerCount.fulfilled,(state,action)=>{
            state.totalCustomerCount=action.payload;
        })

        builder.addCase(EditVendor.fulfilled,(state,action)=>{
            console.log(action.payload)
            state.id = action.payload.id;
            state.name= action.payload.name;
            state.email= action.payload.email;
            state.messname= action.payload.mess_name;
            state.mobile= action.payload.mobile;
            state.address= action.payload.address;
            state.pincode= action.payload.pincode;
            state.image= action.payload.image;
            console.log(action.payload)
        })

        builder.addCase(updateVendor.fulfilled,(state,action)=>{
            window.location.href = '/Vendors';
        })

        builder.addCase(blockVendor.fulfilled,(state,action)=>{

            window.location.href = '/Vendors';
        })
    }
})

export const {setName,setEmail,setMessname,setMobile,setAddress,setPincode,setImage,setSubscriptionPlan}=VendorSlice.actions;
export default VendorSlice.reducer;